import React from "react";

import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import utils from "../../utils";
import Notification from "../../components/Notification";
import Block from "./block";
import io from 'socket.io-client';
import {Menu} from "react-feather";
import ReactNotifications from 'react-browser-notifications';
const config_api_socket = require("../../config/config");
const apiListBanking = require("./api/apiAccountant");
const maxList = 15;

class SidebarLeft extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                waiting_for_handling: [],
                waiting_for_Withdrawal: [],
                handling: [],
            }
        };
    }

    handleClickNotification(event) {
        window.focus()
        this.n.close(event.target.tag);
    }

    handleOnClick(key) {
        if(utils.isEmpty(this.state.data.handling)){
            let action = key.split("-")[0];
            let index = key.split("-")[1];
            let data = utils.getRawData(this.state.data);
            let oderSelected = data[action].splice(index, 1)[0];
            if(!utils.isEmpty(oderSelected)){
                if(action=="waiting_for_Withdrawal" ){
                    apiListBanking.getRequestTransfer(oderSelected.idOder,(err,result)=>  {
                        if(err){
                            Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                        } else {
                            data.handling.push(oderSelected);
                            this.setState({data: data},()=>{
                                this.props.moveDataToContent(Object.assign({'action': action==="waiting_for_handling" ? "nạp tiền":"rút tiền"}, result))
                            });
                            
                        }
                    }); 
                } else{
                apiListBanking.getBanking(oderSelected.idOder,(err,result)=>  {
                        if(err){
                            Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                        } else {
                            data.handling.push(oderSelected);
                            this.setState({data: data},()=>{
                                this.props.moveDataToContent(Object.assign({'action': action==="waiting_for_handling" ? "nạp tiền":"rút tiền"}, result))
                            });
                            
                        }
                    }); 
                }
            }
        }
    }

    handTakeAway(){
        let state = Object.assign({}, this.state);
        state.data.waiting_for_handling.push(state.data.handling[0]);
        state.data.handling=[];
        this.setState(state);
    }

    fnListsocket(){
        apiListBanking.listBanking(maxList,"type=nap",(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                let state = Object.assign({}, this.state);
                state.data.waiting_for_handling=[];
                state.data.waiting_for_Withdrawal=[];
                result.map(((value)=>{
                    if(utils.isEmpty(this.state.data.handling)||value.id !== this.props.dataProcess.id){
                        state.data.waiting_for_handling.push(
                            {
                                phoneNumber: value.customer.phoneNumber,
                                time: value.createdDate,
                                price: value.amount,
                                idOder : value.id,
                                proof: value.proof
                            }
                        );
                    }
                }))

                apiListBanking.listBanking(maxList,"type=12",(err,result)=>  {
                    if(err){
                        Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                    } else {
                        state.data.waiting_for_Withdrawal=[];
                        result.map(((value)=>{
                            if(utils.isEmpty(this.state.data.handling)||value.id !== this.props.dataProcess.id){
                                state.data.waiting_for_Withdrawal.push(
                                    {
                                        phoneNumber: value.customer.phoneNumber,
                                        time: value.createdDate,
                                        price: value.amount,
                                        idOder : value.id,
                                        proof: value.proof
                                    }
                                )
                            }
                        }))
                        this.setState(state);
                    }
                });
                // show thong bao qua browser
                if(this.n.supported()){this.n.show()}
            }
        });
    }

    componentDidMount(){
        const socket = io.connect(config_api_socket.socket_server,{query: `storeId=${utils.getUserinfo().storeId}&username=${utils.getUserinfo().username}`});
        apiListBanking.listBanking(maxList,"type=nap",(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                let state = Object.assign({}, this.state);
                result.map(((value)=>{
                    if(utils.isEmpty(this.state.data.handling)||value.id !== this.props.dataProcess.id){
                        state.data.waiting_for_handling.push(
                            {
                                phoneNumber: value.customer.phoneNumber,
                                time: value.createdDate,
                                price: value.amount,
                                idOder : value.id,
                                proof: value.proof
                            }
                        );
                    }
                }))
                
                this.setState(state);
            }
        });

        apiListBanking.listBanking(maxList,"type=12",(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                let state = Object.assign({}, this.state);
                state.data.waiting_for_Withdrawal=[];
                result.map(((value)=>{
                    if(utils.isEmpty(this.state.data.handling)||value.id !== this.props.dataProcess.id){
                        state.data.waiting_for_Withdrawal.push(
                            {
                                phoneNumber: value.customer.phoneNumber,
                                time: value.createdDate,
                                price: value.amount,
                                idOder : value.id,
                                proof: value.proof
                            }
                        )
                    }
                }))
                this.setState(state);
            }
        });
        socket.on('new_banking_request', this.fnListsocket.bind(this));
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.takeAway){
            this.props.toggle("takeAway");
            apiListBanking.listBanking(maxList,"type=nap",(err,result)=>  {
                if(err){
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                } else {
                    let state = Object.assign({}, this.state);
                    state.data.waiting_for_handling=[];
                    state.data.handling=[];
                    state.data.waiting_for_Withdrawal=[];
                    result.map(((value)=>{
                        state.data.waiting_for_handling.push(
                            {
                                phoneNumber: value.customer.phoneNumber,
                                time: value.createdDate,
                                price: value.amount,
                                idOder : value.id,
                                proof: value.proof
                            }
                        )
                    }))

                    apiListBanking.listBanking(maxList,"type=12",(err,result)=>  {
                        if(err){
                            Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                        } else {
                            state.data.waiting_for_Withdrawal=[];
                            result.map(((value)=>{
                                if(utils.isEmpty(this.state.data.handling)||value.id !== this.props.dataProcess.id){
                                    state.data.waiting_for_Withdrawal.push(
                                        {
                                            phoneNumber: value.customer.phoneNumber,
                                            time: value.createdDate,
                                            price: value.amount,
                                            idOder : value.id,
                                            proof: value.proof
                                        }
                                    )
                                }
                            }))
                            this.setState(state);
                        }
                    });
                }
            });
        }
    }

    render(){
        // xoa dl trong tab dang xu ly
        if(this.props.clearDataHandling){
            let state = utils.getRawData(this.state);
            state.data.handling=[];
            this.props.toggle("clearDataHandling");
            this.setState(state);
        }
        return(
            <React.Fragment>
                <Tabs >
                    <TabList className="d-flex align-items-stretch m-0 p-0">
                        <button className='float-left bg-white border-0 hidden-lg-up' onClick={()=>this.props.toggle("showSidebar")} > <Menu/> </button>
                        <Tab style={{maxWidth: "34%", height:"100%", paddingLeft:"1%", minHeight:"80px"}}>Nạp tiền ({this.state.data.waiting_for_handling.length})&nbsp;&nbsp;</Tab>
                        <Tab style={{maxWidth: "33%", height:"100%", paddingLeft:"1%", minHeight:"80px"}}>Rút tiền ({this.state.data.waiting_for_Withdrawal.length})&nbsp;&nbsp;</Tab>
                        <Tab style={{maxWidth: "33%", height:"100%", paddingLeft:"1%", minHeight:"80px"}}>Đang xử lý ({this.state.data.handling.length})&emsp;&emsp;&emsp;&emsp;</Tab>
                    </TabList>
                    <TabPanel>
                        {
                            this.state.data.waiting_for_handling.length === 0
                                ? "Danh sách trống"
                                :
                                this.state.data.waiting_for_handling.map(({phoneNumber, time, price}, index) => {
                                    return (
                                        <Block key={`waiting_for_handling-${index}`}
                                                id={`waiting_for_handling-${index}`}
                                                phoneNumber={phoneNumber}
                                                time={time}
                                                price={price}
                                                handleOnClick={this.handleOnClick.bind(this)}
                                        />
                                    )
                                })
                        }
                    </TabPanel>
                    <TabPanel>
                        {
                            this.state.data.waiting_for_Withdrawal.length === 0
                                ? "Danh sách trống"
                                :
                                this.state.data.waiting_for_Withdrawal.map(({phoneNumber, time, price}, index) => {
                                    return (
                                        <Block key={`waiting_for_Withdrawal-${index}`}
                                                id={`waiting_for_Withdrawal-${index}`}
                                                phoneNumber={phoneNumber}
                                                time={time}
                                                price={price}
                                                handleOnClick={this.handleOnClick.bind(this)}
                                        />
                                    )
                                })
                        }
                    </TabPanel>
                    <TabPanel>
                        {
                            this.state.data.handling.length === 0
                                ? "Danh sách trống"
                                :
                                this.state.data.handling.map(({phoneNumber, time, price}, index) => {
                                    return (
                                        <Block key={`handling-${index}`}
                                                id={`handling-${index}`}
                                                phoneNumber={phoneNumber}
                                                time={time}
                                                price={price}
                                                handleOnClick={this.handleOnClick.bind(this)}
                                        />
                                    )
                                })
                        }
                    </TabPanel>
                </Tabs>

                {/* <ReactNotifications
                    onRef={ref => (this.n = ref)} // Required
                    title="Cập nhật mới" // Required
                    body="Yêu cầu NẠP/RÚT tiền." // Required
                    icon="https://vesotv.vn/favicon.ico"
                    tag="vesotv"
                    timeout="3000"
                    onClick={event => this.handleClickNotification(event)}
                /> */}
            </React.Fragment>
        )
    }
}

export default SidebarLeft;