const config_network = {
  // "host": "http://192.168.1.229",
  // "port": "3001",
  host: process.env.REACT_APP_config_host_network,
  port: process.env.REACT_APP_config_port_network,
  hostThirdParty:'https://thirdparty.iloto.com.vn',
};
const socket_server = {
  // "host": "http://192.168.1.229",
  // "port": "3002",
  host: process.env.REACT_APP_config_host_socket,
  port: process.env.REACT_APP_config_port_socket,
};
const imageServer = {
  host: process.env.REACT_APP_config_host_image,
  port:
    process.env.REACT_APP_config_port_image === undefined
      ? ""
      : ":" + process.env.REACT_APP_config_port_image,
};

const api_path = config_network.host + ":" + config_network.port + "/api/v1/";
const api_path_thirdParty = config_network.hostThirdParty + "/api/v1/";
const config_api = {
  path: api_path,
  admin: api_path + "admin",
  store: api_path + "store",
  commerce: api_path + "commerce",
  history: api_path + "history",
  attachment: api_path + "attachment",
  customer: api_path + "customer",
  accountant: api_path + "accountant",
  administrative: api_path + "administrative",
  wallet: api_path + "wallet",
  statistic: api_path + "statistic",
  product: api_path + "product",
  pos: api_path + "pos",
  term: api_path + "term",
  notifi: api_path + "admin/notifi/common",
  staffMonitor:api_path+"staff-monitor",
  ticket:api_path+"ticket/ticket-array",
  groupBuyGet:api_path+"group-buy/all",
  groupBuyCreate:api_path+"group-buy/create",
  groupBuyUpdate:api_path+"group-buy/update",
  groupBuyDetail:api_path+"group-buy/detailAdmin",
  kienthietCreate:api_path+"kienthiet/create",
  kienthietGetAll:api_path+"kienthiet/all",
  kienthietDetail:api_path+"kienthiet/detail",
  ticketInfo:api_path+"order/ticketInfo",
  commission:"https://thirdparty.iloto.com.vn/api/v1/third-party/revenue-permate",
  thirdPartyRevenue:api_path_thirdParty+"third-party/revenue-ktmn",
  thirdPartyRevenue_All:api_path_thirdParty+"third-party/revenue",
  commissionDetail:"https://thirdparty.iloto.com.vn/api/v1/third-party/revenue-permate-detail",
};
const socket_topic = {
  new_order: "new_order",
};

function get_urlImage(url) {
  return imageServer.host + imageServer.port + "/media/" + url;
}

const actionHistoryId = [
  {
    id: 11,
    label: "Thêm nhân viên",
  },
  {
    id: 12,
    label: "Xóa nhân viên",
  },
  {
    id: 13,
    label: "Chỉnh sửa thông tin nhân viên",
  },
  {
    id: 14,
    label: "Thêm cửa hàng",
  },
  {
    id: 15,
    label: "xóa cửa hàng",
  },
  {
    id: 16,
    label: "Chỉnh sửa thông tin cửa hàng",
  },
  {
    id: 21,
    label: "Nạp tiền tại quầy",
  },
  {
    id: 22,
    label: "Rút tiền tại quầy",
  },
  {
    id: 25,
    label: "Tiếp nhận đặt vé",
  },
  {
    id: 26,
    label: "Xử lý đơn hàng",
  },
  {
    id: 27,
    label: "Hoàn tác đơn hàng",
  },
  {
    id: 31,
    label: "Nạp tiền qua ngân hàng",
  },
  {
    id: 32,
    label: "Hủy yêu cầu nạp tiền qua ngân hàng",
  },
  {
    id: 35,
    label: "Nạp tăng hạn mức",
  },
  {
    id: 36,
    label: "Rút tiền từ POS",
  },
  {
    id: 41,
    label: "Rút tiền qua ngân hàng",
  },
  {
    id: 42,
    label: "Hủy yêu cầu rút tiền qua ngân hàng",
  },
  {
    id: 51,
    label: "Rút tiền tại cửa hàng",
  },
  {
    id: 52,
    label: "Hủy yêu cầu rút tiền tại cửa hàng",
  },
  {
    id: 60,
    label: "Hủy vé và hoàn trả vé cho vietlott",
  },
  {
    id: 61,
    label: "Hủy vé và không hoàn trả vé cho vietlott",
  },
  {
    id: 62,
    label: "Hệ thống tự hủy vé",
  },
  {
    id: 63,
    label: "Nhân viên hủy vé",
  },
];

const fluctuationType = [
  {
    id: "1",
    label: "Bán vé",
  },
  {
    id: "2",
    label: "Rút tiền tại cửa hàng",
  },
  {
    id: "3",
    label: "Nạp qua VNPAY",
  },
  {
    id: "4",
    label: "Nạp qua ngân hàng",
  },
  {
    id: "5",
    label: "Nạp tiền tại cửa hàng",
  },
  {
    id: "6",
    label: "Cộng tiền trúng số",
  },
  {
    id: "7",
    label: "Hủy đặt vé",
  },
  {
    id: "9",
    label: "Rút tiền về tài Khoản ngân hàng",
  },
  {
    id: "10",
    label: "Rút tiền qua momo",
  },
  {
    id: "11",
    label: "Nạp tiền qua momo",
  },
  {
    id:"12",
    label:"Cộng tiền hoa hồng"
  },
  {
    id:"13",
    label:"Rút về ví"
  },
  
  {
    id:"40",
    label:'Cộng tiền khuyến mãi',
  },
  {
    id:"50",
    label:"Thanh toán qua Megapay (cộng tài khoản phụ)"
  },
  {
    id:"51",
    label:"Nạp qua MegaPay"
  },
  {
    id:"60",
    label:"Nạp qua Gpay (atm)"
  },
  {
    id:"61",
    label:"Nạp qua Gpay (Visa/ Master)"
  },
  {
    id:"62",
    label:"Nạp qua VA"
  },
  {
    id:"63",
    label:"Thanh toán Gpay(Visa/Master)"
  },
];

const gender = [
  { label: "Nam", value: "M" },
  { label: "Nữ", value: "F" },
  { label: "Khác", value: "O" },
];

function getMoneyAfterTransaction(id, beforeTransaction, value) {
  console.log(id, ["1", "2", "9", "10"].includes(id));
  if (["1", "2", "9", "10"].includes(id)) {
    return beforeTransaction - value;
  } else {
    return beforeTransaction + value;
  }
}

function getActionHistoryById(id) {
  let object = actionHistoryId.find((ac) => ac.id == id);
  if (object) return object.label;
  else return null;
}

module.exports = {
  config_network: config_network,
  config_api: config_api,
  socket_server: socket_server.host + ":" + socket_server.port,
  socket_topic: socket_topic,
  get_urlImage: get_urlImage,
  gender: gender,
  actionHistoryId: actionHistoryId,
  rechargeOnDay: fluctuationType,
  getMoneyAfterTransaction: getMoneyAfterTransaction,
  getActionHistoryById: getActionHistoryById,
};
