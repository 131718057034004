const moment = require("moment");
const {
  rechargeOnDay,
  config_api,
  getMoneyAfterTransaction,
  gender,
  getActionHistoryById,
} = require("../../../config/config");
const utils = require("../../../utils");
const axios = require("axios");
const { getNameCityFromCode } = require("../../../config/listCity");
const { getNameDistrictFromCode } = require("../../../config/listDistrict");

function deposit(startDate, endDate, callback) {
  let params = null;
  if (utils.isEmpty(startDate) || utils.isEmpty(endDate)) {
    let date = moment().format("DD-MM-YYYY");
    params = "?startDate=" + date + "&endDate=" + date;
  } else {
    params = "?startDate=" + startDate + "&endDate=" + endDate;
  }

  axios({
    url: config_api.statistic + "/deposit" + params,
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + utils.getAuthToken(),
    },
    data: {},
  })
    .then((result) => {
      return callback(false, result.data);
    })
    .catch((error) => {
      if (error.response) {
        return callback(error.response);
      } else if (error.request) {
        return callback("Đường truyền bị gián đoạn");
      } else {
        return callback(error.message);
      }
    });
}

function winPrize(query, callback) {
  axios({
    url: config_api.statistic + "/prize?" + utils.convertToQuery(query),
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + utils.getAuthToken(),
    },
    data: {},
  })
    .then((result) => {
      for (const TicketWin of result.data.rows) {
        TicketWin.totalPrizeProfit = TicketWin.totalPrizeProfit * 1000;
      }
      return callback(false, result.data);
    })
    .catch((error) => {
      if (error.response) {
        return callback(error.response);
      } else if (error.request) {
        return callback("Đường truyền bị gián đoạn");
      } else {
        return callback(error.message);
      }
    });
}

function staffStatistic(filter, callback) {
  let fieldDelete = [
    "3d",
    "4d",
    "mega",
    "power",
    "keno",
    "total",
    "moneyRecharge",
    "moneyWithdraw",
  ];
  axios({
    url: config_api.statistic + "/staff?" + utils.convertToQuery(filter),
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + utils.getAuthToken(),
    },
  })
    .then((result) => {
      result.data.map((staff) => {
        staff.cityId = getNameCityFromCode(staff.cityId);
        staff.districtId = getNameDistrictFromCode(staff.districtId);
        staff.role = staff.role === "clerk" ? "Bán vé" : "Kế toán";
        staff.isActive = staff.isActive ? "Hoạt động" : "Khóa";
        staff["3d"] =
          staff.statisticTicket.moneyArray[4] +
          staff.statisticTicket.moneyArray[5];
        staff["4d"] = staff.statisticTicket.moneyArray[3];
        staff["mega"] = staff.statisticTicket.moneyArray[1];
        staff["power"] = staff.statisticTicket.moneyArray[2];
        staff["keno"] = staff.statisticTicket.moneyArray[6];
        staff["total"] = staff.statisticTicket.moneyArray[0];
        delete staff.statisticTicket;
        let moneyRecharge = 0;
        let moneyWithdraw = 0;
        for (const totalMoney of staff.statisticTotalMoney) {
          try {
            if (totalMoney.actionId === 21 || totalMoney.actionId === 31) {
              moneyRecharge += parseInt(totalMoney.totalMoney, 10);
            } else if (
              totalMoney.actionId === 51 ||
              totalMoney.actionId === 41
            ) {
              moneyWithdraw += parseInt(totalMoney.totalMoney, 10);
            } else {
              console.log("thieu TH " + totalMoney.actionId);
            }
          } catch (error) {
            return callback(
              "Lỗi convert số tiền sang dạng số nhân viên " + staff.username
            );
          }
        }
        delete staff.statisticTotalMoney;
        staff.moneyRecharge = moneyRecharge;
        staff.moneyWithdraw = moneyWithdraw;
        return staff;
      });

      // filter data >= <=
      result.data = result.data.filter((row) => {
        for (let i = 0; i < fieldDelete.length; i++) {
          if (
            !utils.isEmpty(filter[`gte_${fieldDelete[i]}`]) &&
            row[fieldDelete[i]] < filter[`gte_${fieldDelete[i]}`]
          )
            return false;
          if (
            !utils.isEmpty(filter[`lte_${fieldDelete[i]}`]) &&
            row[fieldDelete[i]] > filter[`lte_${fieldDelete[i]}`]
          )
            return false;
        }
        return true;
      });
      // return data
      return callback(null, result.data);
    })
    .catch((error) => {
      if (error.response) {
        return callback(error.response);
      } else if (error.request) {
        return callback("Đường truyền bị gián đoạn");
      } else {
        return callback(error.message);
      }
    });
}

function customerTransactions(filter, callback) {
  console.log(filter);
  axios({
    url: config_api.statistic + "/transaction",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + utils.getAuthToken(),
    },
    data: utils.removeEmptyData(filter),
  })
    .then((result) => {
      console.log(result);
      result.data.rows.map((row, key) => {
        if(row["wallet.customer.gender"] )
        row["wallet.customer.gender"] = gender.find(
          (g) => g.value === row["wallet.customer.gender"]
        ).label;        
        row["afterTransaction"] = getMoneyAfterTransaction(
          row.fluctuationTypeId,
          row.beforeTransaction,
          row.value
        );
        if(row["fluctuationTypeId"] )
        row["fluctuationTypeId"] = rechargeOnDay.find(
          (f) => f.id === row.fluctuationTypeId
        ).label;
        return row;
      });
      return callback(null, result.data);
    })
    .catch((error) => {
      if (error.response) {
        return callback(error.response);
      } else if (error.request) {
        return callback("Đường truyền bị gián đoạn");
      } else {
        return callback(error.message);
      }
    });
}

function getOrder(query, callback) {
  axios({
    url:
      config_api.statistic +
      "/transaction/get-order?" +
      utils.convertToQuery(query),
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + utils.getAuthToken(),
    },
  })
    .then((result) => {
      if (result.data.ticketArray) {
        result.data.ticketArray = result.data.ticketArray.map((ticket) => {
          if (!utils.isEmpty(ticket.winingNumber)) {
            ticket.winingPrice = 0;
            for (const winPrice of ticket.winingNumber) {
              if (!utils.isEmpty(winPrice[2]))
                ticket.winingPrice += winPrice[2];
            }
          }
          return ticket;
        });
      }
      return callback(null, result.data);
    })
    .catch((error) => {
      if (error.response) {
        return callback(error.response);
      } else if (error.request) {
        return callback("Đường truyền bị gián đoạn");
      } else {
        return callback(error.message);
      }
    });
}

function customerStatistic(filter, callback) {
  axios({
    url: config_api.statistic + "/customer?" + utils.convertToQuery(filter),
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + utils.getAuthToken(),
    },
  })
    .then((result) => {
      result.data.rows.map((row) => {
        row["gender"] = gender.find((g) => g.value === row["gender"]).label;
        return row;
      });
      return callback(null, result.data);
    })
    .catch((error) => {
      if (error.response) {
        return callback(error.response);
      } else if (error.request) {
        return callback("Đường truyền bị gián đoạn");
      } else {
        return callback(error.message);
      }
    });
}
function customerStatisticV2(filter, callback) {
  axios({
    url: config_api.statistic + "/customer-v2?" + utils.convertToQuery(filter),
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + utils.getAuthToken(),
    },
  })
    .then((result) => {
      console.log(result);
      result.data.map((row) => {
        row["gender"] = gender.find((g) => g.value === row["gender"]).label;
        return row;
      });
      return callback(null, result.data.sort(function(a, b) {
        return (a.id - b.id);
    }));
    })
    .catch((error) => {
      if (error.response) {
        return callback(error.response);
      } else if (error.request) {
        return callback("Đường truyền bị gián đoạn");
      } else {
        return callback(error.message);
      }
    });
}
function revenue(filter, callback) {
  axios({
    url: config_api.statistic + "/revenue",
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + utils.getAuthToken(),
    },
    params: filter,
  })
    .then((result) => {
      return callback(null, result.data);
    })
    .catch((error) => {
      if (error.response) {
        return callback(error.response);
      } else if (error.request) {
        return callback("Đường truyền bị gián đoạn");
      } else {
        return callback(error.message);
      }
    });
}

function moneyHolding(filter, callback) {
  axios({
    url: config_api.statistic + "/transaction/total",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + utils.getAuthToken(),
    },
    data: utils.removeEmptyData(filter),
  })
    .then((result) => {
      for (const i in result.data.total) {
        if (result.data.total.hasOwnProperty(i)) {
          if (result.data.total[i] === null) result.data.total[i] = 0;
        }
      }
      return callback(null, result.data);
    })
    .catch((error) => {
      if (error.response) {
        return callback(error.response);
      } else if (error.request) {
        return callback("Đường truyền bị gián đoạn");
      } else {
        return callback(error.message);
      }
    });
}

function moneyHoldingOnday(filter, callback) {
  let body = filter;
  axios({
    url: config_api.statistic + "/transaction/total/byday",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + utils.getAuthToken(),
    },
    data: utils.removeEmptyData(body),
  })
    .then((result) => {
      return callback(null, result.data);
    })
    .catch((error) => {
      if (error.response) {
        return callback(error.response);
      } else if (error.request) {
        return callback("Đường truyền bị gián đoạn");
      } else {
        return callback(error.message);
      }
    });
}

function rejectTicket(filter, callback) {
  axios({
    url: config_api.statistic + "/ticket?" + utils.convertToQuery(filter),
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + utils.getAuthToken(),
    },
  })
    .then((result) => {
      return callback(null, result.data);
    })
    .catch((error) => {
      if (error.response) {
        return callback(error.response);
      } else if (error.request) {
        return callback("Đường truyền bị gián đoạn");
      } else {
        return callback(error.message);
      }
    });
}
function changePassword(phoneNumber, callback) {
  axios({
    url: config_api.commerce + "/customer_reset_pass" ,
    method: "POST",
    data:{
      phoneNumber:phoneNumber
    },
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + utils.getAuthToken(),
    },
  })
    .then((result) => {
      return callback(null, result.data);
    })
    .catch((error) => {
      if (error.response) {
        return callback(error.response);
      } else if (error.request) {
        return callback("Đường truyền bị gián đoạn");
      } else {
        return callback(error.message);
      }
    });
}

async function getComissionRev(fromdate,todate){
  try{
    let result = await axios({
      url: config_api.commission+`?fromdate=${fromdate}&todate=${todate}` ,
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + utils.getAuthToken(),
      },
    });
    return [null,result.data]
  }catch(error){
    if (error.response) {
      return [error.response];
    } else if (error.request) {
      return ["Đường truyền bị gián đoạn"];
    } else {
      return [error.message];
    }
  }
  
}
async function getComissionDetail(fromdate,todate){
  try{
    let result = await axios({
      url: config_api.commissionDetail+`?fromdate=${fromdate}&todate=${todate}` ,
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + utils.getAuthToken(),
      },
    });
    return [null,result.data]
  }catch(error){
    if (error.response) {
      return [error.response];
    } else if (error.request) {
      return ["Đường truyền bị gián đoạn"];
    } else {
      return [error.message];
    }
  }
  
}
function revenueThirdParty(filter, callback) {
  axios({
    url: config_api.thirdPartyRevenue_All,
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + utils.getAuthToken(),
    },
    params: filter,
  })
    .then((result) => {
      return callback(null, result.data);
    })
    .catch((error) => {
      if (error.response) {
        return callback(error.response);
      } else if (error.request) {
        return callback("Đường truyền bị gián đoạn");
      } else {
        return callback(error.message);
      }
    });
}
export {
  deposit,
  winPrize,
  staffStatistic,
  customerTransactions,
  customerStatistic,
  revenue,
  moneyHolding,
  moneyHoldingOnday,
  rejectTicket,
  getOrder,
  customerStatisticV2,
  changePassword,
  getComissionRev,
  revenueThirdParty,
  getComissionDetail
};
