require("./StringPrototype");
const get_urlImage = require("../config/config").get_urlImage;
function getRawData(obj){
    return JSON.parse(JSON.stringify(obj));
}

function returnThisWhenNull(source, result_when_null){
    return (isEmpty(source) ? result_when_null : source);
}

const smalltalk = require('smalltalk');

function alert(msg) {
    smalltalk.alert("vesotv cho biết", msg);
}

function confirm(msg, callback) {
    smalltalk.confirm("vesotv cho biết", msg)
        .then(() => {
            if (callback) return callback(true)
        })
        .catch(() => {
            if (callback) return callback(false)
        })
}

function confirmInput(msg, callback) {
    smalltalk
        .prompt("vesotv cho biết", msg,"")
        .then((value) => {
            if (callback) return callback(value)
        })
        .catch(() => {
            if (callback) return callback(false)
        })
}

function getUserinfo(){
    try{
        let userInfo = localStorage.getItem("userInfo");
        if(isEmpty(userInfo))
            return null;
        return JSON.parse(userInfo);
    } catch (e) {
        return null;
    }
}

function isEmpty(data) {
    if (data === "" || data === null || data === undefined)
        return true;
    if (Array.isArray(data)) {
        if (data.length === 0)
            return true;
    }
    if ((typeof data) === "object") {
        for (let key in data) {
            if (data.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    return false;
}


function dataURLtoFile(ImageURL, filename) {
    if(ImageURL){
        if(ImageURL){
        
            var block = ImageURL.split(";");
            var contentType = block[0].split(":")[1];
            var realData = block[1].split(",")[1];
            var sliceSize =  512;
            var byteCharacters = atob(realData);
            var byteArrays = [];
            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);
                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            var blob = new Blob(byteArrays, {type: contentType});
            return blob;
        }else{
            return null;
        }
    }   
    else{
        return null;
    }
  }

function getAuthToken(){
    try{
        let userInfo = localStorage.getItem("userInfo");
        if(isEmpty(userInfo))
            return null;
        return JSON.parse(userInfo).token;
    } catch (e) {
        return null;
    }
}

function checkAuth() {
    if (isEmpty(getAuthToken())){
        window.location.replace("/");
        return false;
    }
    return true;
}

function clickElement(idElement){
    document.getElementById(idElement).click();
}

function cleanLocalStore(){
    localStorage.removeItem("userInfo");
}

function logOut(){
    confirm("Xác nhận đăng xuất tài khoản", (check) => {
        if (check) {
            cleanLocalStore();
            window.location.href = "/";
        }
    });
    
}

function convertMoneyFomat(amount){
    return (amount*1000).getMoneyFormat() + " đ";
}

function getMoneyFormat(amount) {
    if(isEmpty(amount)) return 0;
    if(typeof(amount)==="number") return (amount*1000).getMoneyFormat()
    else {
        try {
            return (parseInt(amount)*1000).getMoneyFormat()
        } catch (error) {
            return ("error")
        }
    }
}

function getURLImage (url){
    return(
        get_urlImage(url)
    )
}

/**
 * Nếu d<10 => đưa về dạng có độ dài là 2 0d
 * @param {number} d 
 */
function pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
}
function convertDate(dateInput){
    if(dateInput===null || dateInput === undefined || dateInput === ""){
        let dateNow = new Date();
        return pad(dateNow.getDate()) + "-" + pad(dateNow.getMonth() + 1) + "-" + dateNow.getFullYear();
    }else{
        return pad(dateInput.getDate()) + "-" + pad(dateInput.getMonth() + 1) + "-" + dateInput.getFullYear()
    }
}

function compareName(a, b) {
    const genreA = a.name.toUpperCase();
    const genreB = b.name.toUpperCase();
    let comparison = 0;
    if (genreA > genreB) {
        comparison = 1;
    } else if (genreA < genreB) {
        comparison = -1;
    }
    return comparison;
}

function detectDevice(device,param){
    let innerWidth = window.innerWidth;
    if(typeof(device) === "string"){
        return isDevice(device,innerWidth);
    } else{
        let check = isDevice( device[0], innerWidth);
        for (let index = 1; index < device.length; index++) {
            let checkElement = isDevice( device[index], innerWidth);
            if(param === "and"){
                check = check && checkElement;
            } else if (param === "or"){
                check = check || checkElement;
            }
        }
        return check;
    }
}

function isDevice(device,Width) {
    if(Width === undefined) Width = window.innerWidth;
    switch (device) {
        case "fullhd":
            return(Width>=1920)
        case "hd":
            return(Width>=1242 && Width<1920)
        case "ipad":
            return(Width>=1024 && Width<1242)
        default:
            return(Width<1024)
    }
}

function toggle1(obj,param1,val) {
    try {
        if(val===undefined){
            obj[param1] = !obj[param1];
        } else{
            obj[param1] = val;
        }
        return obj;
    } catch (error) {
        return 0;
    }
}

function toggle2(obj,param1,param2,val) {
    try {
        if(val===undefined){
            obj[param1][param2] = !obj[param1][param2];
        } else{
            obj[param1][param2] = val;
        }
        return obj;
    } catch (error) {
            return 0;
    }
}

function toggle3(obj,param1,param2,param3,val) {
    try {
        if(val===undefined){
            obj[param1][param2][param3] = !obj[param1][param2][param3];
        } else{
            obj[param1][param2][param3] = val;
        }
        return obj;
    } catch (error) {
        return 0;
    }
}

function removeEmptyData(obj) {
    for (var propName in obj) { 
        if ( isEmpty(obj[propName]) ) {
            delete obj[propName];
        }
    }
    return obj
}

function range(first,end) {
    let arr = []
    for (let index = first; index <= end; index++) {
        arr.push(index);
    }
    return arr;
}

function convertToQuery(obj) {
    var str = [];
    for (var p in obj)
        if (!isEmpty(obj[p])) {
            switch (typeof(obj[p])) {
                case "string":
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    break;
                case "number":
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    break;
                case "object":
                    for (let i = 0; i < obj[p].length; i++) {
                        str.push(encodeURIComponent(p) + "[]=" + encodeURIComponent(obj[p][i]));
                    }
                    break;
                default:
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    break;
            }
        }
    return str.join("&");
}

function checkObjectUndefined(object,field) {
    let length = field.length;
    for (let i = 0; i < length; i++) {
        if(object[field[i]]=== null || object[field[i]]=== undefined) return false;
        else if(i===length-1) return true
        object = object[field[i]];
    }
}

function changeObjectKey(object,fromkey,convertKey) {
    object.map(ob=>{
        ob[convertKey] = ob[fromkey];
        return ob;
    })
    return object;
}

function objectToArray(object,fromkey) {
    object.map(ob=>{
        ob = ob[fromkey];
        return ob;
    })
    return object;
}

Array.prototype.sortBy = function(p) {
    return this.slice(0).sort(function(a,b) {
        return (a[p] > b[p]) ? 1 : (a[p] < b[p]) ? -1 : 0;
    });
};
Date.prototype.dateFormater = function() {
    if (isEmpty(this)) return null
    return this.getDate() + "-" + (this.getMonth() + 1) + "-" + this.getFullYear()
};



module.exports = {
    getRawData: getRawData,
    returnThisWhenNull: returnThisWhenNull,
    alert: alert,
    confirm: confirm,
    confirmInput: confirmInput,
    isEmpty: isEmpty,
    getUserinfo: getUserinfo,
    getAuthToken: getAuthToken,
    checkAuth: checkAuth,
    dataURLtoFile: dataURLtoFile,
    clickElement: clickElement,
    cleanLocalStore: cleanLocalStore,
    logOut: logOut,
    convertMoneyFomat:convertMoneyFomat,
    getURLImage:getURLImage,
    convertDate: convertDate,
    compareName: compareName,
    detectDevice: detectDevice,
    toggle1: toggle1,
    toggle2: toggle2,
    toggle3: toggle3,
    removeEmptyData:removeEmptyData,
    convertToQuery: convertToQuery,
    range: range,
    checkObjectUndefined: checkObjectUndefined,
    changeObjectKey: changeObjectKey,
    objectToArray: objectToArray,
    getMoneyFormat: getMoneyFormat
};